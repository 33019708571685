import { ReactNode } from 'react'
import { IResume } from '../models/Resume'
import { DefaultSeqNumPerStyle, validResumeStyles } from '../utils/Constants'
import Amber from './Amber'
import Aspen from './Aspen'
import Aurora from './Aurora'
import Blaze from './Blaze'
import Cascade from './Cascade'
import Frost from './Frost'
import Harbor from './Harbor'
import Horizon from './Horizon'
import LoadingResume from './Loading'
import LoadingState from './LoadingState'
import Lunar from './Lunar'
import Minimal from './Minimal'
import Oasis from './Oasis'
import Orchid from './Orchid'
import Pinnacle from './Pinnacle'
import Plume from './Plume'
import Quartz from './Quartz'
import Rustic from './Rustic'
import Sapphire from './Sapphire'
import Savannah from './Savannah'
import Solstice from './Solstice'
import Vivid from './Vivid'

export const getView = (myResume: IResume): ReactNode => {
    if (!myResume || !myResume.style) {
        return LoadingState({ message: 'Loading...' })
    }

    switch (myResume.currentStyle) {
        case validResumeStyles.Plume:
            return Plume(myResume)
        case validResumeStyles.Pinnacle:
            return Pinnacle(myResume)
        case validResumeStyles.Quartz:
            return Quartz(myResume)
        case validResumeStyles.Horizon:
            return Horizon(myResume)
        case validResumeStyles.Lunar:
            return Lunar(myResume)
        case validResumeStyles.Amber:
            return Amber(myResume)
        case validResumeStyles.Frost:
            return Frost(myResume)
        case validResumeStyles.Solstice:
            return Solstice(myResume)
        case validResumeStyles.Aurora:
            return Aurora(myResume)
        case validResumeStyles.Orchid:
            return Orchid(myResume)
        case validResumeStyles.Savannah:
            return Savannah(myResume)
        case validResumeStyles.Vivid:
            return Vivid(myResume)
        case validResumeStyles.Harbor:
            return Harbor(myResume)
        case validResumeStyles.Cascade:
            return Cascade(myResume)
        case validResumeStyles.Blaze:
            return Blaze(myResume)
        case validResumeStyles.Minimal:
            return Minimal(myResume)
        case validResumeStyles.Oasis:
            return Oasis(myResume)
        case validResumeStyles.Rustic:
            return Rustic(myResume)
        case validResumeStyles.Sapphire:
            return Sapphire(myResume)
        case validResumeStyles.Aspen:
            return Aspen(myResume)
        default:
            return LoadingResume()
    }
}

export const getStyleSeqNumForStyle = (style: validResumeStyles) => {
    switch (style) {
        case validResumeStyles.Plume:
            return DefaultSeqNumPerStyle.Plume
        case validResumeStyles.Pinnacle:
            return DefaultSeqNumPerStyle.Pinnacle
        case validResumeStyles.Quartz:
            return DefaultSeqNumPerStyle.Quartz
        case validResumeStyles.Horizon:
            return DefaultSeqNumPerStyle.Horizon
        case validResumeStyles.Lunar:
            return DefaultSeqNumPerStyle.Lunar
        case validResumeStyles.Amber:
            return DefaultSeqNumPerStyle.Amber
        case validResumeStyles.Frost:
            return DefaultSeqNumPerStyle.Frost
        case validResumeStyles.Solstice:
            return DefaultSeqNumPerStyle.Solstice
        case validResumeStyles.Aurora:
            return DefaultSeqNumPerStyle.Aurora
        case validResumeStyles.Orchid:
            return DefaultSeqNumPerStyle.Orchid
        case validResumeStyles.Savannah:
            return DefaultSeqNumPerStyle.Savannah
        case validResumeStyles.Vivid:
            return DefaultSeqNumPerStyle.Vivid
        case validResumeStyles.Harbor:
            return DefaultSeqNumPerStyle.Harbor
        case validResumeStyles.Cascade:
            return DefaultSeqNumPerStyle.Cascade
        case validResumeStyles.Blaze:
            return DefaultSeqNumPerStyle.Blaze
        case validResumeStyles.Minimal:
            return DefaultSeqNumPerStyle.Minimal
        case validResumeStyles.Oasis:
            return DefaultSeqNumPerStyle.Oasis
        case validResumeStyles.Rustic:
            return DefaultSeqNumPerStyle.Rustic
        case validResumeStyles.Sapphire:
            return DefaultSeqNumPerStyle.Sapphire
        case validResumeStyles.Aspen:
            return DefaultSeqNumPerStyle.Aspen
        default:
            return DefaultSeqNumPerStyle.Minimal
    }
}
