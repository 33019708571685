import * as colors from '@mui/material/colors'

export const reactColors: string[] = [
    colors.red[100],
    colors.red[200],
    colors.red[300],
    colors.red[400],
    colors.red[500],
    colors.red[600],
    colors.red[700],
    colors.red[800],
    colors.red[900],
    colors.pink[100],
    colors.pink[200],
    colors.pink[300],
    colors.pink[400],
    colors.pink[500],
    colors.pink[600],
    colors.pink[700],
    colors.pink[800],
    colors.pink[900],
    colors.purple[100],
    colors.purple[200],
    colors.purple[300],
    colors.purple[400],
    colors.purple[500],
    colors.purple[600],
    colors.purple[700],
    colors.purple[800],
    colors.purple[900],
    colors.deepPurple[100],
    colors.deepPurple[200],
    colors.deepPurple[300],
    colors.deepPurple[400],
    colors.deepPurple[500],
    colors.deepPurple[600],
    colors.deepPurple[700],
    colors.deepPurple[800],
    colors.deepPurple[900],
    colors.indigo[100],
    colors.indigo[200],
    colors.indigo[300],
    colors.indigo[400],
    colors.indigo[500],
    colors.indigo[600],
    colors.indigo[700],
    colors.indigo[800],
    colors.indigo[900],
    colors.blue[100],
    colors.blue[200],
    colors.blue[300],
    colors.blue[400],
    colors.blue[500],
    colors.blue[600],
    colors.blue[700],
    colors.blue[800],
    colors.blue[900],
    colors.lightBlue[100],
    colors.lightBlue[200],
    colors.lightBlue[300],
    colors.lightBlue[400],
    colors.lightBlue[500],
    colors.lightBlue[600],
    colors.lightBlue[700],
    colors.lightBlue[800],
    colors.lightBlue[900],
    colors.cyan[100],
    colors.cyan[200],
    colors.cyan[300],
    colors.cyan[400],
    colors.cyan[500],
    colors.cyan[600],
    colors.cyan[700],
    colors.cyan[800],
    colors.cyan[900],
    colors.teal[100],
    colors.teal[200],
    colors.teal[300],
    colors.teal[400],
    colors.teal[500],
    colors.teal[600],
    colors.teal[700],
    colors.teal[800],
    colors.teal[900],
    colors.green[100],
    colors.green[200],
    colors.green[300],
    colors.green[400],
    colors.green[500],
    colors.green[600],
    colors.green[700],
    colors.green[800],
    colors.green[900],
    colors.lightGreen[100],
    colors.lightGreen[200],
    colors.lightGreen[300],
    colors.lightGreen[400],
    colors.lightGreen[500],
    colors.lightGreen[600],
    colors.lightGreen[700],
    colors.lightGreen[800],
    colors.lightGreen[900],
    colors.lime[100],
    colors.lime[200],
    colors.lime[300],
    colors.lime[400],
    colors.lime[500],
    colors.lime[600],
    colors.lime[700],
    colors.lime[800],
    colors.lime[900],
    colors.yellow[100],
    colors.yellow[200],
    colors.yellow[300],
    colors.yellow[400],
    colors.yellow[500],
    colors.yellow[600],
    colors.yellow[700],
    colors.yellow[800],
    colors.yellow[900],
    colors.amber[100],
    colors.amber[200],
    colors.amber[300],
    colors.amber[400],
    colors.amber[500],
    colors.amber[600],
    colors.amber[700],
    colors.amber[800],
    colors.amber[900],
    colors.orange[100],
    colors.orange[200],
    colors.orange[300],
    colors.orange[400],
    colors.orange[500],
    colors.orange[600],
    colors.orange[700],
    colors.orange[800],
    colors.orange[900],
    colors.deepOrange[100],
    colors.deepOrange[200],
    colors.deepOrange[300],
    colors.deepOrange[400],
    colors.deepOrange[500],
    colors.deepOrange[600],
    colors.deepOrange[700],
    colors.deepOrange[800],
    colors.deepOrange[900],
    colors.brown[100],
    colors.brown[200],
    colors.brown[300],
    colors.brown[400],
    colors.brown[500],
    colors.brown[600],
    colors.brown[700],
    colors.brown[800],
    colors.brown[900],
    colors.grey[100],
    colors.grey[200],
    colors.grey[300],
    colors.grey[400],
    colors.grey[500],
    colors.grey[600],
    colors.grey[700],
    colors.grey[800],
    colors.grey[900],
    colors.blueGrey[100],
    colors.blueGrey[200],
    colors.blueGrey[300],
    colors.blueGrey[400],
    colors.blueGrey[500],
    colors.blueGrey[600],
    colors.blueGrey[700],
    colors.blueGrey[800],
    colors.blueGrey[900]
]

export interface IHTMLColorNameItem {
    backgroundColor: string
    textColor: string
    borderBackgroundColor: string
    borderColor: string
    hoverBackgroundColor: string
    hoverTextColor: string
    buttonBackgroundColor: string
    buttonTextColor: string
    inputBackgroundColor: string
    inputTextColor: string
    linkBackgroundColor: string
    linkTextColor: string
    buttonTextSecondary: string
}

export const hideColorAlt = {
    backgroundColor: 'textColor',
    textColor: 'backgroundColor',
    borderBackgroundColor: 'borderColor',
    borderColor: 'borderColor',
    hoverBackgroundColor: 'hoverTextColor',
    hoverTextColor: 'hoverBackgroundColor',
    buttonBackgroundColor: 'buttonTextColor',
    buttonTextColor: 'buttonBackgroundColor',
    inputBackgroundColor: 'inputTextColor',
    inputTextColor: 'inputBackgroundColor',
    linkBackgroundColor: 'linkTextColor',
    linkTextColor: 'linkBackgroundColor',
    buttonTextSecondary: 'buttonBackgroundColor'
}

export interface IHTMLColorName {
    navigationBar: IHTMLColorNameItem
    footer: IHTMLColorNameItem
    login: IHTMLColorNameItem
    header: IHTMLColorNameItem
    card: IHTMLColorNameItem
}
