import AddIcon from '@mui/icons-material/Add'
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Fab,
    FormControl,
    FormLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Switch
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Header } from './components/Header'
import { EmploymentUniverseContext } from './context/EmploymentUniverseContext'
import { useAddResume, useDeleteResume, useResume, useResumeInfo, useUpdateResumeField } from './hooks/useResume'
import { getLabel } from './labels/labelUtils'
import { IResume } from './models/Resume'
import { IResumeInfo } from './models/User'
import { loggedOutNavigationItems, mainNavigationItems } from './navigation/AppNavigationItems'
import EditStyleDialog from './pages/dialog/EditStyleDialog'
import OrderResumeManager from './pages/dialog/OrderResumeManager'
import { validResumeStyles } from './utils/Constants'
import { getView } from './views/viewUtils'

export const AppLayout = ({ children }: { children: JSX.Element }) => {
    const euContext = useContext(EmploymentUniverseContext)
    const navigationItems = euContext.user.email ? mainNavigationItems : loggedOutNavigationItems
    const isLoggedIn = Boolean(euContext.user._id)
    const [isCardOpen, setIsCardOpen] = useState(false)
    const [showAdditionalItems, setShowAdditionalItems] = useState(false)
    const [showResumeActions, setShowResumeActions] = useState(false)
    const [openOrderManager, setOpenOrderManager] = useState(false)
    const [viewResume, setViewResume] = useState<ReactNode>(getView(euContext.resume))
    const [openEditStyle, setOpenEditStyle] = useState<boolean>(false)
    const [selectedResumeId, setSelectedResumeId] = useState<string>('')
    const { data: resumeResponse } = useResume(selectedResumeId)
    const { data: resInfosResponse, isFetching: isFetchingResumeInfo } = useResumeInfo(euContext.user._id)
    const { mutate: updateResumeField } = useUpdateResumeField()
    const { mutate: addResumeToDB } = useAddResume()
    const { mutate: deleteResumeToDB } = useDeleteResume()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setViewResume(getView(euContext.resume))
    }, [euContext.resume])

    useEffect(() => {
        if (resumeResponse?.resume) {
            euContext.setResume(resumeResponse.resume)
            navigate('/dashboard')
        }
    }, [resumeResponse])

    const deleteResume = async (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        deleteResumeToDB(
            { resumeId: euContext.resume._id, user: euContext.user },
            {
                onSuccess: (data) => {
                    setSelectedResumeId(data._id)
                }
            }
        )
    }

    const changeIsPublic = async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const resume: IResume = euContext.resume
        updateResumeField(
            { id: resume._id, fieldName: 'isPublic', fieldValue: evt.target.checked },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const addResume = async (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        addResumeToDB(
            { user: euContext.user },
            {
                onSuccess: (data) => {
                    setSelectedResumeId(data._id)
                }
            }
        )
    }

    const changeStyle = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const currentStyle: validResumeStyles = newValue as validResumeStyles

        updateResumeField(
            { id: resume._id, fieldName: 'currentStyle', fieldValue: currentStyle },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    setViewResume(getView(data.resume))
                }
            }
        )
    }

    const handleFabClick = () => setIsCardOpen((prev) => !prev)

    const handleNavigation = (path: string) => {
        navigate(path)
        setIsCardOpen(false)
    }

    const changeResume = (event: SelectChangeEvent<string>) => {
        event.preventDefault()

        const newValue = event.target.value

        if (!newValue) return
        setSelectedResumeId(newValue)
    }

    const handleToggleAdditionalItems = () => setShowAdditionalItems((prev) => !prev)

    const popoverActions = [
        {
            label: getLabel('addResume'),
            onClick: addResume
        },
        {
            label: getLabel('deleteResume'),
            onClick: deleteResume
        },
        {
            label: getLabel('viewResume'),
            onClick: () => navigate('/print-resume')
        },
        {
            label: getLabel('orderResume'),
            onClick: () => setOpenOrderManager(true)
        },
        {
            label: getLabel('editResumeStyle'),
            onClick: () => setOpenEditStyle(true)
        }
    ]

    return (
        <Box sx={{ display: 'flex', position: 'relative', height: 'auto', backgroundColor: grey[50] }}>
            <Stack
                sx={{
                    paddingTop: '85px',
                    paddingBottom: '50px'
                }}
                width='100%'>
                <Header />
                <Stack
                    alignItems='center'
                    justifyContent='center'>
                    {children}
                </Stack>
            </Stack>

            {isLoggedIn && (
                <>
                    <Fab
                        color='secondary'
                        onClick={handleFabClick}
                        sx={{
                            position: 'fixed',
                            bottom: 75,
                            right: 50
                        }}>
                        <AddIcon />
                    </Fab>

                    {isCardOpen && (
                        <Card
                            sx={{
                                position: 'fixed',
                                bottom: 100,
                                right: 50,
                                width: 300,
                                zIndex: 1000
                            }}>
                            <CardContent>
                                <List component='nav'>
                                    {showResumeActions && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '5px'
                                                }}>
                                                {popoverActions.map((action, index) => (
                                                    <Button
                                                        key={index}
                                                        variant='outlined'
                                                        sx={{ width: '100%' }}
                                                        onClick={action.onClick}>
                                                        {action.label}
                                                    </Button>
                                                ))}
                                            </Box>
                                            {!isFetchingResumeInfo && resInfosResponse?.resumeInfos && resInfosResponse.resumeInfos.length > 1 && (
                                                <Select
                                                    size='small'
                                                    value={euContext.resume._id?.toString()}
                                                    onChange={(e) => changeResume(e)}
                                                    sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                                                    {resInfosResponse.resumeInfos.map((res: IResumeInfo, index: number) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={res.resume_id.toString()}>
                                                                {res.resumeName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            )}
                                            {euContext.resume.style && (
                                                <>
                                                    <Select
                                                        value={euContext.resume.currentStyle}
                                                        onChange={(e) => changeStyle(e)}
                                                        size='small'
                                                        sx={{ width: '100%' }}>
                                                        {Object.keys(validResumeStyles).map((val, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={val}>
                                                                    {val}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </>
                                            )}
                                            <FormControl>
                                                <FormLabel>{getLabel('public')}</FormLabel>
                                                <Switch
                                                    checked={euContext.resume.isPublic}
                                                    onChange={changeIsPublic}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                    {location.pathname === '/dashboard' && euContext.user.isSuperAdmin && (
                                        <>
                                            <ListItemButton onClick={() => setShowResumeActions((prev) => !prev)}>
                                                <ListItemText primary={showResumeActions ? getLabel('hideResumeItems') : getLabel('showResumeItems')} />
                                            </ListItemButton>
                                            <Divider />
                                        </>
                                    )}
                                    {showAdditionalItems && euContext.user.isSuperAdmin && (
                                        <>
                                            {navigationItems
                                                .filter((item) => item.label !== 'logout' && item.label !== 'register')
                                                .map((item) => (
                                                    <ListItemButton
                                                        key={item.id}
                                                        onClick={() => handleNavigation(item.route)}>
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText
                                                            sx={{ textTransform: 'capitalize' }}
                                                            primary={item.label}
                                                        />
                                                    </ListItemButton>
                                                ))}
                                        </>
                                    )}
                                    {euContext.user.isSuperAdmin && (
                                        <ListItemButton onClick={handleToggleAdditionalItems}>
                                            <ListItemText primary={showAdditionalItems ? getLabel('hideNavigationItems') : getLabel('showNavigationItems')} />
                                        </ListItemButton>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
            <EditStyleDialog
                openEditStyle={openEditStyle}
                onCloseEditStyle={() => {
                    setOpenEditStyle(false)
                    viewResume
                }}
            />
            <OrderResumeManager
                open={openOrderManager}
                onClose={() => setOpenOrderManager(false)}
            />
        </Box>
    )
}
