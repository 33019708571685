import { Grid2, Stack } from '@mui/material'
import { type ClassNameMap, withStyles } from '@mui/styles'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationSummaryComponent from '../components/resume/educations/EducationSummaryComponent'
import NameComponent from '../components/resume/name/NameComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import backgroundImage from '../images/background/th-2022402106.jpg'
import { IResume } from '../models/Resume'

const BackgroundStyle = withStyles({
    root: {
        backgroundImage: `url(${backgroundImage})`, // Replace with your image path
        backgroundSize: 'cover',
        height: '20vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start'
    }
})(({ classes }: { classes: ClassNameMap }) => (
    <Grid2
        container
        className={classes.root}>
        <Grid2
            size={3}
            paddingLeft={3}>
            <PictureComponent
                picWidth={150}
                picHeight={150}
                picAlign='start'
                picMT={2}
            />
        </Grid2>
        <Grid2
            size={9}
            mt={5}>
            <NameComponent />
        </Grid2>
    </Grid2>
))

const Pinnacle = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Pinnacle.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack direction='column'>
            <BackgroundStyle />
            <Stack
                direction='row'
                fontSize={resume.style[resume.currentStyle].BodyFontSize}
                fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
                fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
                fontFamily={resume.style[resume.currentStyle].BodyFontName}
                marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
                columnGap={0}>
                <Stack
                    direction='column'
                    width='230px'
                    paddingLeft={3}
                    paddingRight={3}
                    rowGap={`${resume.style[resume.currentStyle].SpaceSection}px`}>
                    <SummaryComponent />
                    <ContactComponent includeAddress={true} />
                    <EducationSummaryComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='585px'
                    paddingLeft={3}
                    paddingRight={3}
                    sx={{ backgroundColor: '#f7f9f9' }}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return <value.cardComponent key={value.cardType} />
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Pinnacle
