import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Box, Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { type CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { isAxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useRegister } from '../hooks/useAuth'
import { useEnvironments } from '../hooks/useEnvironments'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { registerGoogle, registerReturnInterface } from '../utils/DBUtils'
import { passwordMeetsCriteria } from '../utils/Util'
import LoadingState from '../views/LoadingState'

export const RegisterPage = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [emailValue, setEmailValue] = useState<string>('')
    const [passwordValue, setPasswordValue] = useState<string>('')
    const [confirmPasswordValue, setConfirmPasswordValue] = useState<string>('')
    const [firstNameValue, setFirstNameValue] = useState<string>('')
    const [lastNameValue, setLastNameValue] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [showOtherRegisterOptions, setShowOtherRegisterOptions] = useState<boolean>(false)
    const { mutate: registerMutate, isPending: registerLoading } = useRegister()
    const { data: environmentResponse, isLoading: environmentLoading } = useEnvironments()
    const navigate = useNavigate()

    const onRegisterClickedWrapper = (): void => {
        onRegisterClicked().catch((error) => {
            console.error(error)
        })
    }

    const handleLinkedInRegister = async () => {
        const params = 'scrollbars=no,resizable=no,status=no,locationbar=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100'
        const linkedInCallback: string = environmentResponse?.linkedinOAuthURLRegister ?? ''

        const onMessage = (event: MessageEvent) => {
            if (event.data.user) euContext.setUser(event.data.user)
            if (event.data.resume) euContext.setResume(event.data.resume)

            if (event.data.user) {
                window.removeEventListener('message', onMessage)
                navigate('/')
            }
        }
        window.addEventListener('message', onMessage)

        window.open(linkedInCallback.replace(/%5B%5Bdomain%5D%5D/g, euContext.domain + '.'), '_linkedIn', params)
    }

    const handleGoogleRegister = async (codeResponse: CredentialResponse) => {
        if (environmentResponse && codeResponse.credential) {
            setErrorMessage('')
            try {
                const user = jwtDecode(codeResponse.credential)

                const response: registerReturnInterface = await registerGoogle(user, euContext.domain)

                if (response?.error) {
                    setErrorMessage(response.error)
                    return
                }

                if (response?.user) {
                    euContext.setUser(response.user)
                }

                if (response?.resume) {
                    euContext.setResume(response.resume)
                }

                navigate('/')
            } catch (err) {
                setErrorMessage('An unknown error occurred during Google sign-up')
            }
        }
    }

    const onRegisterClicked = async (): Promise<void> => {
        try {
            registerMutate(
                { email: emailValue, password: passwordValue, firstName: firstNameValue, lastName: lastNameValue, subdomain: euContext.domain },
                {
                    onSuccess: (response) => {
                        euContext.setUser(response.user)
                        euContext.setResume(response.resume)
                        navigate('/')
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error.response) {
                            setErrorMessage(error.response.data.message || error.response.data)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                }
            )
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage('An unknown error occurred')
            }
        }
    }

    const handleClickShowPassword = (): void => {
        setShowPassword((show) => !show)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
    }

    const handleClickShowConfirmPassword = (): void => {
        setShowConfirmPassword((show) => !show)
    }

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
    }

    return (
        <>
            {environmentLoading && <LoadingState />}
            {!environmentLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                        marginBottom: '100px'
                    }}>
                    <Box
                        sx={{
                            width: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '24px',
                            borderRadius: '4px',
                            backgroundColor: euContext?.htmlColors?.login.backgroundColor,
                            borderColor: euContext?.htmlColors?.login.borderColor
                        }}>
                        <Typography
                            component='h1'
                            variant='h5'
                            sx={{ mb: 2, color: euContext?.htmlColors?.login.textColor }}>
                            {getLabel('register')}
                        </Typography>
                        <Stack
                            spacing={2}
                            width='100%'>
                            {errorMessage && (
                                <Alert
                                    severity='error'
                                    variant='filled'>
                                    {errorMessage}
                                </Alert>
                            )}
                            <TextField
                                variant='standard'
                                label={getLabel('firstName')}
                                value={firstNameValue}
                                onChange={(e) => {
                                    setFirstNameValue(e.target.value)
                                }}
                                fullWidth
                                autoFocus
                            />
                            <TextField
                                variant='standard'
                                label={getLabel('lastName')}
                                value={lastNameValue}
                                onChange={(e) => {
                                    setLastNameValue(e.target.value)
                                }}
                                fullWidth
                            />
                            <TextField
                                variant='standard'
                                label={getLabel('emailAddress')}
                                type='email'
                                value={emailValue}
                                onChange={(e) => {
                                    setEmailValue(e.target.value)
                                }}
                                placeholder={getLabel('placeholderEmail')}
                                fullWidth
                                autoComplete='email'
                            />
                            <TextField
                                variant='standard'
                                label={getLabel('password')}
                                type={showPassword ? 'text' : 'password'}
                                value={passwordValue}
                                onChange={(e) => {
                                    setPasswordValue(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label={getLabel('ariaTextPassword')}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                                placeholder={getLabel('passwordPlaceholder')}
                                fullWidth
                                autoComplete='current-password'
                            />
                            <TextField
                                variant='standard'
                                label={getLabel('confirmPassword')}
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPasswordValue}
                                onChange={(e) => {
                                    setConfirmPasswordValue(e.target.value)
                                }}
                                error={passwordValue !== confirmPasswordValue}
                                helperText={passwordValue !== confirmPasswordValue ? getLabel('passwordsNoMatch') : ''}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label={getLabel('ariaTextPassword')}
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}>
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                                fullWidth
                                autoComplete='confirm-password'
                            />
                            <Divider />
                            <Button
                                variant='contained'
                                disabled={!emailValue || !passwordValue || !passwordMeetsCriteria(passwordValue) || passwordValue !== confirmPasswordValue || registerLoading}
                                onClick={onRegisterClickedWrapper}
                                sx={{
                                    width: '100%',
                                    borderRadius: '20px',
                                    backgroundColor: euContext?.htmlColors?.login.buttonBackgroundColor,
                                    color: euContext?.htmlColors?.login.buttonTextColor,
                                    '&:hover': {
                                        backgroundColor: euContext?.htmlColors?.login.hoverBackgroundColor,
                                        color: euContext?.htmlColors?.login.hoverTextColor
                                    }
                                }}>
                                {registerLoading ? getLabel('registering') : getLabel('registerBtn')}
                            </Button>
                            <Typography
                                component='p'
                                variant='body2'
                                sx={{
                                    color: euContext?.htmlColors?.login.textColor,
                                    textDecoration: 'none',
                                    fontSize: '0.875rem',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    },
                                    mb: 2
                                }}
                                onClick={() => setShowOtherRegisterOptions(!showOtherRegisterOptions)}>
                                {getLabel('otherRegisterOptions')}
                            </Typography>
                            {showOtherRegisterOptions && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                    <GoogleLogin
                                        text='signup_with'
                                        onSuccess={handleGoogleRegister}
                                        onError={() => console.error('error')}
                                        width={'362px'}
                                    />
                                    <Button
                                        variant='contained'
                                        onClick={handleLinkedInRegister}
                                        sx={{ width: '80%', marginBottom: '10px', textTransform: 'none' }}>
                                        {getLabel('registerWithLinkedIn')}
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default RegisterPage
