import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Input, MenuItem, Modal, Select, Typography } from '@mui/material'
import React, { MouseEvent, useState } from 'react'
import { useAddClient, useClients, useRemoveClient, useUpdateClient } from '../../hooks/useClients'
import { getLabel } from '../../labels/labelUtils'
import { IClients, client_types } from '../../models/Clients'
import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'
import CustomTable from '../CustomTable'

const headCells = [
    { id: 'actions', label: 'Edit', width: '30px' },
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type', width: '100px' },
    { id: 'subdomain', label: 'Subdomain', width: '80px' },
    { id: 'default_email', label: 'Email' },
    { id: 'created', label: 'Created', width: '80px' },
    { id: 'updated', label: 'Updated', width: '80px' }
]

export const ClientsComponent = () => {
    const [openAddClient, setOpenAddClient] = useState(false)
    const [newClient, setNewClient] = useState({} as IClients)

    const { data: clientsResponse, isLoading: clientsIsLoading } = useClients()
    const { mutate: addClient, isPending: isAdding, error: addError } = useAddClient()
    const { mutate: updateClient, isPending: isUpdating, error: updateError } = useUpdateClient()
    const { mutate: removeClient, isPending: isRemoving, error: removeError } = useRemoveClient()

    const handleAddClient = async (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        addClient(newClient, {
            onSuccess: () => {
                setNewClient({} as IClients)
                setOpenAddClient(false)
            }
        })
    }

    const handleUpdateClient = async (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        updateClient(newClient, {
            onSuccess: () => {
                setNewClient({} as IClients)
                setOpenAddClient(false)
            }
        })
    }

    const handleRemoveClient = async (rClient: IClients) => {
        removeClient(rClient, {
            onSuccess: () => {
                setNewClient({} as IClients)
                setOpenAddClient(false)
            }
        })
    }

    const enableAdd = () => {
        if (
            newClient.name &&
            newClient.name.length > 0 &&
            newClient.subdomain &&
            newClient.subdomain.length > 0 &&
            newClient.client_url &&
            newClient.client_url.length > 0 &&
            newClient.default_email &&
            newClient.default_email.length > 0
        ) {
            return true
        } else {
            return false
        }
    }

    const onClose = (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
            setNewClient({} as IClients)
            setOpenAddClient(false)
        }
    }

    if (isAdding || isRemoving) {
        return <LoadingState message={getLabel('updatingClients')} />
    }
    if (addError || removeError) {
        return <ErrorState message={getLabel('errorUpdatingClients')} />
    }

    if (clientsIsLoading) {
        return <LoadingState message={getLabel('loadingClients')} />
    }

    const rows = clientsResponse?.clients.map((client) => ({
        actions: (
            <>
                <span
                    onClick={() => {
                        setNewClient(client)
                        setOpenAddClient(true)
                    }}>
                    <EditOutlinedIcon />
                </span>
                <span onClick={() => handleRemoveClient(client)}>
                    <DeleteOutlinedIcon />
                </span>
            </>
        ),
        ...client
    }))

    return (
        <Box
            paddingLeft={3}
            paddingBottom={7}>
            <Typography>{getLabel('listOfClients')}!!</Typography>
            <div
                onClick={() => {
                    setOpenAddClient(true)
                }}>
                <AddCircleOutlineOutlinedIcon />
            </div>
            <Modal
                open={openAddClient}
                onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}>
                    <Button onClick={(evt) => onClose(evt, 'buttonClick')}>
                        <Typography>Close</Typography>
                    </Button>
                    {updateError && <ErrorState message={'Error updating client'} />}
                    <Input
                        fullWidth
                        id='clientName'
                        value={newClient.name || ''}
                        onChange={(evt) => setNewClient({ ...newClient, name: evt.target.value })}
                        placeholder='Enter Client Name'
                    />
                    <Select
                        value={newClient.type || ''}
                        onChange={(evt) => setNewClient({ ...newClient, type: evt.target.value as client_types })}
                        id='clientType'
                        fullWidth>
                        {Object.values(client_types).map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <Input
                        fullWidth
                        value={newClient.subdomain || ''}
                        onChange={(evt) => setNewClient({ ...newClient, subdomain: evt.target.value })}
                        id='subdomain'
                        placeholder='Enter Subdomain'
                    />
                    <Input
                        fullWidth
                        value={newClient.client_url || ''}
                        onChange={(evt) => setNewClient({ ...newClient, client_url: evt.target.value })}
                        id='clientURL'
                        placeholder='Enter Client URL'
                    />
                    <Input
                        fullWidth
                        value={newClient.default_email || ''}
                        onChange={(evt) => setNewClient({ ...newClient, default_email: evt.target.value })}
                        id='clientEmail'
                        placeholder='Enter Client Email'
                    />
                    <Input
                        fullWidth
                        value={newClient.talent_community_id || ''}
                        onChange={(evt) => setNewClient({ ...newClient, talent_community_id: evt.target.value })}
                        id='clientTalentCommunityId'
                        placeholder='Enter Client Talent Community ID'
                    />
                    <Input
                        fullWidth
                        value={newClient.public_key || ''}
                        onChange={(evt) => setNewClient({ ...newClient, public_key: evt.target.value })}
                        id='clientPublicKey'
                        placeholder='Enter Client Public Key'
                    />
                    <Input
                        fullWidth
                        value={newClient.private_key || ''}
                        onChange={(evt) => setNewClient({ ...newClient, private_key: evt.target.value })}
                        id='clientPrivateKey'
                        placeholder='Enter Client Private Key'
                    />
                    <Input
                        fullWidth
                        value={newClient.security_cert || ''}
                        onChange={(evt) => setNewClient({ ...newClient, security_cert: evt.target.value })}
                        id='clientSecurityCert'
                        placeholder='Enter Client Security Cert'
                    />
                    <Input
                        fullWidth
                        value={newClient.certificate_authority || ''}
                        onChange={(evt) => setNewClient({ ...newClient, certificate_authority: evt.target.value })}
                        id='clientCertAuth'
                        placeholder='Enter Client Certificate Authority'
                    />
                    <Button
                        disabled={!enableAdd() || isUpdating}
                        onClick={newClient._id ? handleUpdateClient : handleAddClient}>
                        {newClient._id ? 'Edit' : 'Add'}
                    </Button>
                </Box>
            </Modal>
            <CustomTable
                columns={headCells}
                rows={rows}
            />
        </Box>
    )
}
