import { IStyles } from '../models/Styles'
import Amber from '../resumeStyles/AmberStyle'
import Aspen from '../resumeStyles/AspenStyle'
import Aurora from '../resumeStyles/AuroraStyle'
import Blaze from '../resumeStyles/BlazeStyle'
import Cascade from '../resumeStyles/CascadeStyle'
import Frost from '../resumeStyles/FrostStyle'
import Harbor from '../resumeStyles/HarborStyle'
import Horizon from '../resumeStyles/HorizonStyle'
import Lunar from '../resumeStyles/LunarStyle'
import Minimal from '../resumeStyles/MinimalStyle'
import Oasis from '../resumeStyles/OasisStyle'
import Orchid from '../resumeStyles/OrchidStyle'
import Pinnacle from '../resumeStyles/PinnacleStyle'
import Plume from '../resumeStyles/PlumeStyle'
import Quartz from '../resumeStyles/QuartzStyle'
import Rustic from '../resumeStyles/RusticStyle'
import Sapphire from '../resumeStyles/SapphireStyle'
import Savannah from '../resumeStyles/SavannahStyle'
import Solstice from '../resumeStyles/SolsticeStyle'
import Vivid from '../resumeStyles/VividStyle'

const usersLink = '/api/v1/users'
const userLink = (id: string) => `${usersLink}/${id}`
const loginApiLink = `${usersLink}/login`
const loginGoogleLink = `${usersLink}/loginGoogle`
const loginLinkedInLink = `${usersLink}/loginLinkedIn`
const logoutLink = `${usersLink}/logout`
const registerLink = `${usersLink}/register`
const registerLinkedInLink = `${usersLink}/registerLinkedIn`
const registerGoogleLink = `${usersLink}/registerGoogle`
const resetPasswordLink = `${usersLink}/resetPassword`
const forgotPasswordLink = `${usersLink}/forgotPassword`
const restoreLink = '/api/v1/restore'
const restoreUsersLink = (id: string) => `${restoreLink}/${id}`
const restoreGetUsersLink = (subdomain: string) => `${restoreLink}/${subdomain}`
const resumeInfoLink = (userId: string) => `${usersLink}/${userId}/resumeInfos`
const aiLink = '/api/v1/ai'
const askOpenAILink = `${aiLink}/askOpenAI`
const askGoogleAILink = `${aiLink}/askGoogleAI`
const resumesLink = '/api/v1/resumes'
const importResumeLink = `${resumesLink}/import`
const viewResumeLink = (domain: string, firstName: string, lastName: string, resumeName: string, location: string, state: string) =>
    `/api/v1/view/${domain}/${firstName}/${lastName}/${resumeName}/${location}/${state}`
const resumeLink = (id: string) => `${resumesLink}/${id}`
const checkIfPublicLink = (id: string) => `${resumeLink(id)}/public`
const affiliationLink = (id: string) => `${resumeLink(id)}/affiliations`
const educationLink = (id: string) => `${resumeLink(id)}/educations`
const employmentLink = (id: string) => `${resumeLink(id)}/employments`
const honorLink = (id: string) => `${resumeLink(id)}/honors`
const licenseLink = (id: string) => `${resumeLink(id)}/licenses`
const projectLink = (id: string) => `${resumeLink(id)}/projects`
const publicationLink = (id: string) => `${resumeLink(id)}/publications`
const referenceLink = (id: string) => `${resumeLink(id)}/references`
const talentLink = (id: string) => `${resumeLink(id)}/talents`
const trainingLink = (id: string) => `${resumeLink(id)}/trainings`
const volunteerLink = (id: string) => `${resumeLink(id)}/volunteers`
const updatePictureLink = (id: string) => `${resumeLink(id)}/updatePicture`
const clientsLink = '/api/v1/clients'
const stripeLink = '/api/v1/stripe'
const checkoutSessionLink = `${stripeLink}/create-checkout-session`
const sessionStatusLink = (sessionId: string) => `${stripeLink}/session-status?session_id=${sessionId}`
const clientLink = (id: string) => `${clientsLink}/${id}`
const environmentLink = '/api/v1/environment'
const clientExistLink = (subdomian: string) => `${environmentLink}/${subdomian}/exists`
const clientColorLink = (subdomian: string) => `${clientsLink}/${subdomian}/htmlColors`
const clientUsersLink = (subdomain: string) => `${clientsLink}/${subdomain}/users`
const statementsLink = '/api/v1/statements'
const statementLink = (id: string) => `${statementsLink}/${id}`
const getStripePaymentsLink = `${stripeLink}/payments`
const getPaymentMethodsLink = (customerId: string) => `${stripeLink}/payment-methods/${customerId}`
const getDistinctStatementCategoriesLink = `${statementsLink}/categories`
const getDistinctStatementJobTitlesLink = (category: string) => `${getDistinctStatementCategoriesLink}/${category}`
const getStatementsByCategoryJobTitleLink = (category: string, jobTitle: string) => `${getDistinctStatementCategoriesLink}/${category}/jobTitles/${jobTitle}`
const tokensLink = '/api/v1/tokens'
const tokenRefreshLink = `${tokensLink}/refresh`

export {
    affiliationLink,
    askGoogleAILink,
    askOpenAILink,
    checkIfPublicLink,
    checkoutSessionLink,
    clientColorLink,
    clientExistLink,
    clientLink,
    clientsLink,
    clientUsersLink,
    educationLink,
    employmentLink,
    environmentLink,
    forgotPasswordLink,
    getDistinctStatementCategoriesLink,
    getDistinctStatementJobTitlesLink,
    getPaymentMethodsLink,
    getStatementsByCategoryJobTitleLink,
    getStripePaymentsLink,
    honorLink,
    importResumeLink,
    licenseLink,
    loginApiLink,
    loginGoogleLink,
    loginLinkedInLink,
    logoutLink,
    projectLink,
    publicationLink,
    referenceLink,
    registerGoogleLink,
    registerLink,
    registerLinkedInLink,
    resetPasswordLink,
    restoreGetUsersLink,
    restoreUsersLink,
    resumeInfoLink,
    resumeLink,
    resumesLink,
    sessionStatusLink,
    statementLink,
    statementsLink,
    talentLink,
    tokenRefreshLink,
    tokensLink,
    trainingLink,
    updatePictureLink,
    userLink,
    usersLink,
    viewResumeLink,
    volunteerLink
}

export const MAX_RESUMES = 25

export const notAddable = -2
export const notShown = -1

export enum cardTypes {
    accomplishments = 'accomplishments',
    affiliations = 'affiliations',
    capabilities = 'capabilities',
    educations = 'educations',
    employments = 'employments',
    honors = 'honors',
    licenses = 'licenses',
    objective = 'objective',
    other = 'other',
    personal = 'personal',
    projects = 'projects',
    professional = 'professional',
    publications = 'publications',
    references = 'references',
    skills = 'skills',
    summary = 'summary',
    talents = 'talents',
    trainings = 'trainings',
    volunteers = 'volunteers'
}

export type DefaultSeqNumPerStyleDetailType = {
    [card in cardTypes]?: number
}

export enum validResumeStyles {
    Plume = 'Plume',
    Pinnacle = 'Pinnacle',
    Quartz = 'Quartz',
    Horizon = 'Horizon',
    Lunar = 'Lunar',
    Amber = 'Amber',
    Frost = 'Frost',
    Solstice = 'Solstice',
    Aurora = 'Aurora',
    Orchid = 'Orchid',
    Savannah = 'Savannah',
    Vivid = 'Vivid',
    Harbor = 'Harbor',
    Cascade = 'Cascade',
    Blaze = 'Blaze',
    Minimal = 'Minimal',
    Oasis = 'Oasis',
    Rustic = 'Rustic',
    Sapphire = 'Sapphire',
    Aspen = 'Aspen'
}

export type DefaultSeqNumPerStyleType = {
    [style in validResumeStyles]: DefaultSeqNumPerStyleDetailType
}

// -2 do not ever show, -1 not visible but can show, positive number is order or card
export const DefaultSeqNumPerStyle: DefaultSeqNumPerStyleType = {
    Plume: { employments: 1, educations: 0, summary: notAddable, skills: notAddable },
    Pinnacle: { employments: 1, educations: notAddable, summary: notAddable, skills: 0 },
    Quartz: { employments: 1, educations: notAddable, summary: notAddable, skills: 0 },
    Horizon: { employments: 1, educations: notAddable, summary: 0, skills: notAddable },
    Lunar: { employments: 1, educations: notAddable, summary: 0, skills: notAddable },
    Amber: { skills: notAddable, employments: notAddable, educations: notAddable, licenses: notAddable, summary: 0, accomplishments: 1, capabilities: 2 },
    Frost: { summary: 0, employments: 1, educations: 2, references: 3, skills: notAddable, trainings: notAddable, accomplishments: notAddable },
    Solstice: { educations: notAddable, skills: notAddable, capabilities: notAddable, other: notAddable, summary: 0, employments: 1 },
    Aurora: { employments: 1, educations: notAddable, summary: 0, skills: notAddable, accomplishments: notAddable, trainings: notAddable },
    Orchid: { employments: 1, educations: 2, projects: 3, summary: 0, skills: notAddable, trainings: notAddable },
    Savannah: { employments: 0, educations: notAddable, summary: notAddable, skills: notAddable, other: notAddable },
    Vivid: { summary: 0, employments: 1, educations: notAddable, skills: notAddable },
    Harbor: { employments: 0, skills: 1, summary: notAddable, educations: notAddable, capabilities: notAddable, trainings: notAddable },
    Cascade: { summary: 0, employments: 1, educations: notAddable, skills: notAddable },
    Blaze: { summary: 0, employments: 1, educations: 2, skills: 3, capabilities: 4 },
    Minimal: { summary: 0, employments: 1 },
    Oasis: { summary: 0, employments: 3, educations: 4, skills: 2, accomplishments: 1, professional: 5 },
    Rustic: { employments: 0, educations: notAddable, skills: notAddable, other: notAddable },
    Sapphire: { summary: 0, employments: 1, educations: 3, skills: 2 },
    Aspen: { summary: 0, employments: 1, educations: notAddable, skills: notAddable }
}

export type DefaultMaxSeqNumPerStyleType = {
    [style in validResumeStyles]: number
}

export const DefaultMaxSeqNumPerStyle: DefaultMaxSeqNumPerStyleType = {
    Plume: 1,
    Pinnacle: 1,
    Quartz: 1,
    Horizon: 1,
    Lunar: 1,
    Amber: 2,
    Frost: 3,
    Solstice: 1,
    Aurora: 1,
    Orchid: 3,
    Savannah: 0,
    Vivid: 1,
    Harbor: 1,
    Cascade: 1,
    Blaze: 4,
    Minimal: 1,
    Oasis: 5,
    Rustic: 0,
    Sapphire: 3,
    Aspen: 1
}

export type ResumeStylesType = {
    [style in validResumeStyles]: IStyles
}

export const ResumeStyles: ResumeStylesType = {
    Plume,
    Pinnacle,
    Quartz,
    Horizon,
    Lunar,
    Amber,
    Frost,
    Solstice,
    Aurora,
    Orchid,
    Savannah,
    Vivid,
    Harbor,
    Cascade,
    Blaze,
    Minimal,
    Oasis,
    Rustic,
    Sapphire,
    Aspen
}
