import { yupResolver } from '@hookform/resolvers/yup'
import Snackbar from '@material-ui/core/Snackbar'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { type CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { isAxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import FieldSigninText from '../components/forms/FieldSigninText'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useGoogleLogin, useLogin } from '../hooks/useAuth'
import { useEnvironments } from '../hooks/useEnvironments'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import LoadingState from '../views/LoadingState'
import { getLoginDefaultValues } from './forms/defaultValues/FormDefaultValues'
import { LoginSchema } from './forms/schemas/FormSchemas'

export const LoginPage = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState('')
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [showOtherLoginOptions, setShowOtherLoginOptions] = useState(false)
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [showPassword, setShowPassword] = useState(false)

    const { mutate: loginMutate, isPending: loginLoading } = useLogin()
    const { mutate: googleLoginMutate } = useGoogleLogin()
    const { data: environmentResponse, isLoading: environmentLoading } = useEnvironments()
    const navigate = useNavigate()

    const formContext = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: getLoginDefaultValues(),
        mode: 'onBlur',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    const handleLinkedInSignIn = async () => {
        const params = 'scrollbars=no,resizable=no,status=no,locationbar=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100'
        const linkedInCallback: string = environmentResponse?.linkedinOAuthURLSignin ?? ''

        const onMessage = (event: MessageEvent) => {
            if (event.data.user) euContext.setUser(event.data.user)
            if (event.data.resume) euContext.setResume(event.data.resume)

            if (event.data.user) {
                window.removeEventListener('message', onMessage)
                navigate('/')
            }
        }
        window.addEventListener('message', onMessage)
        window.open(linkedInCallback.replace(/%5B%5Bdomain%5D%5D/g, euContext.domain + '.'), '_linkedIn', params)
    }

    const handleGoogleSignIn = async (codeResponse: CredentialResponse) => {
        setErrorMessage('')
        if (environmentResponse && codeResponse.credential) {
            try {
                const user = jwtDecode(codeResponse.credential)

                googleLoginMutate(user, {
                    onSuccess: (response) => {
                        euContext.setUser(response.user)
                        euContext.setResume(response.resume)
                        navigate('/')
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error?.response?.data) {
                            setErrorMessage(error.response.data.message)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                })
            } catch (err: unknown) {
                if (isAxiosError(err) && err.response) {
                    setErrorMessage(err.response.data.message)
                } else {
                    setErrorMessage('An unknown error occurred: ' + err)
                }
            }
        }
    }

    const submitIfEnter = (key: string): void => {
        if (key === 'Enter') onLoginClickedWrapper()
    }

    const handleClickShowPassword = (): void => {
        setShowPassword((show) => !show)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
    }

    const onLoginClickedWrapper = (): void => {
        onLoginClicked().catch((error) => {
            setErrorMessage(error)
        })
    }

    const onLoginClicked = async (): Promise<void> => {
        try {
            const { email, password } = formContext.getValues()
            loginMutate(
                { email, password, subdomain: euContext.domain },
                {
                    onSuccess: (response) => {
                        if (response.user.resetPasswordUuid) {
                            setShowSnackbar(true)
                        } else {
                            euContext.setUser(response.user)
                            euContext.setResume(response.resume)
                            navigate('/')
                        }
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error.response) {
                            setErrorMessage(error.response.data.message || error.response.data)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                }
            )
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage('An unknown error occurred')
            }
        }
    }

    return (
        <>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={() => setShowSnackbar(false)}
                message={getLabel('resetPasswordBanner')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            {environmentLoading ? (
                <LoadingState />
            ) : (
                <FormProvider {...formContext}>
                    <form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: euContext?.htmlColors?.login.backgroundColor,
                                borderColor: euContext?.htmlColors?.login.borderColor,
                                marginTop: '50px',
                                marginBottom: '180px'
                            }}>
                            <Box
                                sx={{
                                    width: '470px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '24px',
                                    borderRadius: '4px',
                                    backgroundColor: euContext?.htmlColors?.login.backgroundColor
                                }}>
                                <Typography
                                    component='h1'
                                    variant='h5'
                                    sx={{ mb: 1, color: euContext?.htmlColors?.login.textColor }}>
                                    {getLabel('login')}
                                </Typography>

                                <Typography
                                    component='p'
                                    variant='body2'
                                    sx={{ mb: 1, color: euContext?.htmlColors?.login.textColor }}>
                                    {getLabel('loginSubText')}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        width: '75%'
                                    }}>
                                    <FieldSigninText
                                        name='email'
                                        autoComplete='current-email'
                                        label={getLabel('email')}
                                        placeholder={getLabel('placeholderEmail')}
                                        onChange={() => {
                                            setErrorMessage('')
                                        }}
                                        onKeyDown={(e) => {
                                            submitIfEnter(e.key)
                                        }}
                                        error={errorMessage !== ''}
                                        sx={{ width: '100%' }}
                                        autoFocus
                                    />
                                    <FieldSigninText
                                        name='password'
                                        label={getLabel('password')}
                                        autoComplete='current-password'
                                        onChange={() => {
                                            setErrorMessage('')
                                        }}
                                        onKeyDown={(e) => {
                                            submitIfEnter(e.key)
                                        }}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <IconButton
                                                aria-label={getLabel('ariaTextPassword')}
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        }
                                        sx={{ width: '100%' }}
                                        error={errorMessage !== ''}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '75%',
                                        mb: 2
                                    }}>
                                    <Typography
                                        component={Link}
                                        to='/forgot-password'
                                        variant='body2'
                                        sx={{
                                            color: euContext?.htmlColors?.login.textColor,
                                            textDecoration: 'none',
                                            fontSize: '0.875rem',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}>
                                        {getLabel('forgotPassword')}
                                    </Typography>
                                </Box>
                                <Button
                                    variant='contained'
                                    disabled={loginLoading}
                                    onClick={onLoginClickedWrapper}
                                    sx={{
                                        width: '30%',
                                        borderRadius: '20px',
                                        fontSize: '.7rem',
                                        backgroundColor: euContext?.htmlColors?.login.buttonBackgroundColor,
                                        color: euContext?.htmlColors?.login.buttonTextColor,
                                        marginBottom: '10px',
                                        '&:hover': {
                                            backgroundColor: euContext?.htmlColors?.login.hoverBackgroundColor,
                                            color: euContext?.htmlColors?.login.hoverTextColor
                                        }
                                    }}>
                                    {loginLoading ? getLabel('loginBtnLoading') : getLabel('loginBtn')}
                                </Button>
                                <Typography
                                    component='p'
                                    variant='body2'
                                    sx={{
                                        color: euContext?.htmlColors?.login.textColor,
                                        textDecoration: 'none',
                                        fontSize: '0.875rem',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        },
                                        mb: 1
                                    }}
                                    onClick={() => setShowOtherLoginOptions(!showOtherLoginOptions)}>
                                    {getLabel('otherLoginOptions')}
                                </Typography>
                                {showOtherLoginOptions && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', gap: '10px' }}>
                                        <GoogleLogin
                                            onSuccess={handleGoogleSignIn}
                                            onError={() => console.error('error')}
                                            width={'337px'}
                                        />

                                        <Button
                                            variant='contained'
                                            onClick={handleLinkedInSignIn}
                                            sx={{ width: '100%', marginBottom: '10px', textTransform: 'none' }}>
                                            {getLabel('loginWithLinkedIn')}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            )}
        </>
    )
}
