import { Alignments, DateDelims, DateFormats, FontCases, FontNames, FontStyles, FontWeights, IStyles } from '../models/Styles'
import { validResumeStyles } from '../utils/Constants'

const Lunar: IStyles = {
    StyleName: 'Lunar' as validResumeStyles,
    PrimaryFontName: FontNames.Roboto,
    SecondaryFontName: FontNames.Montserrat,
    AlternateFontName: FontNames.Roboto,
    NameFontName: 'SecondaryFontName',
    NameFontSize: 48,
    NameFontWeight: FontWeights.Bold,
    NameFontStyle: FontStyles.Initial,
    NameAlign: Alignments.Center,
    NameFontCase: FontCases.Capitalize,
    NameFontColor: '#000000',
    NameFontLetterSpacing: 1,
    TitleFontName: 'PrimaryFontName',
    TitleFontSize: 14,
    TitleFontWeight: FontWeights.Bold,
    TitleFontStyle: FontStyles.Initial,
    TitleAlign: Alignments.Center,
    TitleFontCase: FontCases.None,
    TitleFontColor: '#000000',
    TitleFontLetterSpacing: 1,
    AddressFontName: 'PrimaryFontName',
    AddressFontSize: 12,
    AddressFontWeight: FontWeights.Initial,
    AddressFontStyle: FontStyles.Initial,
    AddressAlign: Alignments.Center,
    AddressFontCase: FontCases.None,
    AddressFontColor: '#000000',
    AddressFontLetterSpacing: 1,
    SectionFontName: 'PrimaryFontName',
    SectionFontSize: 16,
    SectionFontWeight: FontWeights.Bold,
    SectionFontStyle: FontStyles.Initial,
    SectionAlign: Alignments.Start,
    SectionFontCase: FontCases.Uppercase,
    SectionFontColor: '#000000',
    SectionFontLetterSpacing: 1,
    DateFontName: 'PrimaryFontName',
    DateFontSize: 12,
    DateFontWeight: FontWeights.Initial,
    DateFontStyle: FontStyles.Initial,
    DateAlign: Alignments.Start,
    DateFontCase: FontCases.Capitalize,
    DateFontColor: '#000000',
    DateFontLetterSpacing: 1,
    DateFormat: DateFormats.MMMyyyy,
    DateDelim: DateDelims.Dash,
    CompanyFontName: 'PrimaryFontName',
    CompanyFontSize: 12,
    CompanyFontWeight: FontWeights.Bold,
    CompanyFontStyle: FontStyles.Initial,
    CompanyAlign: Alignments.Start,
    CompanyFontCase: FontCases.None,
    CompanyFontColor: '#000000',
    CompanyFontLetterSpacing: 1,
    JobTitleFontName: 'PrimaryFontName',
    JobTitleFontSize: 12,
    JobTitleFontWeight: FontWeights.Bold,
    JobTitleFontStyle: FontStyles.Initial,
    JobTitleAlign: Alignments.Start,
    JobTitleFontCase: FontCases.None,
    JobTitleFontColor: '#000000',
    JobTitleFontLetterSpacing: 1,
    BodyFontName: 'PrimaryFontName',
    BodyFontSize: 10,
    BodyFontWeight: FontWeights.Initial,
    BodyFontStyle: FontStyles.Initial,
    BodyAlign: Alignments.Start,
    BodyFontCase: FontCases.None,
    BodyFontColor: '#000000',
    BodyFontLetterSpacing: 1,
    Special: '',
    Alignment: 'Left',
    SpaceHeader: 16,
    SpaceSectionTitle: 6,
    SpaceCard: 6,
    SpaceSection: 10,
    SpaceBorder: 1,
    BorderLocation: '',
    BorderStyle: '',
    seqNum: 1,
    ShowGraphic: true
}

export default Lunar
