import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { AllDefaultCards, defaultCard } from 'utils/Util'
import HorizontalContactComponent from '../components/resume/contact/HorizontalContactComponent'
import TitleComponent from '../components/resume/title/TitleComponent'

const Blaze = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Blaze.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack width='100%'>
            <Stack
                direction='row'
                columnGap={2}
                alignItems='flex-start'
                justifyContent='space-between'
                marginBottom={`${style.SpaceHeader}px`}>
                <Stack
                    direction='column'
                    width='100%'>
                    <NameComponent sx={{ mb: 0, lineHeight: 1 }} />
                    <TitleComponent sx={{ mb: 0.25, lineHeight: 1.25 }} />
                    <HorizontalContactComponent
                        sx={{ lineHeight: 1 }}
                        includeAddress={false}
                        includePhone={true}
                        includeEmail={true}
                        includeLinkedin={true}
                        includeIcons={true}
                        delim=''
                        columnGap={16}
                    />
                </Stack>
            </Stack>
            {orderUsedCards.map((value: defaultCard) => {
                return (
                    <value.cardComponent
                        key={value.cardType}
                        cardSeperator={true}
                    />
                )
            })}
        </Stack>
    )
}

export default Blaze
