import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactHorizontalComponent from '../components/resume/contact/ContactHorizontalComponent'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Minimal = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Minimal.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack
            width='100%'
            marginTop={`${resume.style[resume.currentStyle].SpaceSection}px`}>
            <NameComponent />
            <ContactHorizontalComponent
                hideCity={true}
                hideStreet={true}
                hideZip={true}
            />
            <br />
            {orderUsedCards.map((value: defaultCard) => {
                return (
                    <value.cardComponent
                        key={value.cardType}
                        showLabel={false}
                    />
                )
            })}
        </Stack>
    )
}

export default Minimal
