import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { Box } from '@mui/material'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import OtherComponent from '../components/resume/simpleCards/OtherComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Savannah = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Savannah.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack
            direction='row'
            columnGap={3}
            width='100%'
            marginTop={`${resume.style[resume.currentStyle].SpaceSection}px`}>
            <Stack
                direction='column'
                rowGap={0}
                width='590px'>
                <Box marginBottom={`${resume.style[resume.currentStyle].SpaceHeader}px`}>
                    <NameComponent />
                    <TitleComponent />
                    <SummaryComponent showLabel={false} />
                </Box>
                {orderUsedCards.map((value: defaultCard) => {
                    return (
                        <value.cardComponent
                            key={value.cardType}
                            showLabel={false}
                        />
                    )
                })}
            </Stack>
            <Stack
                direction='column'
                bgcolor='#F7F4FF'
                width='225px'
                rowGap={resume.style[resume.currentStyle].SpaceSection}
                paddingLeft={3}>
                <ContactComponent
                    includeIcons={false}
                    includeAddress={true}
                    hideCity={true}
                    hideStreet={true}
                    hideZip={true}
                />
                <SkillsBulletListComponent />
                <EducationListComponent />
                <OtherComponent />
            </Stack>
        </Stack>
    )
}

export default Savannah
