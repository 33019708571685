import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, IconButton, Snackbar, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import FieldSigninText from '../components/forms/FieldSigninText'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useResetPassword } from '../hooks/useAuth'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

const passwordMeetsCriteria = (password: string): boolean => {
    const criteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    return criteria.test(password)
}

const ResetPasswordPage = (): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const location = useLocation()
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    const navigate = useNavigate()
    const { mutate: resetPasswordMutate, isPending } = useResetPassword()
    const methods = useForm({
        defaultValues: {
            password: '',
            confirmPassword: ''
        }
    })

    const uuid = location.search.split('?uuid=')[1].split('&userId=')[0]

    const onButtonClicked = (data: { password: string; confirmPassword: string }): void => {
        if (data.password !== data.confirmPassword) {
            setErrorMessage(getLabel('passwordsNoMatch'))
            return
        }

        if (!passwordMeetsCriteria(data.password)) {
            setErrorMessage(getLabel('passwordsCriteriaNoMatch'))
            return
        }
        if (!uuid) {
            setErrorMessage(getLabel('passwordInvalidLink'))
            return
        }

        resetPasswordMutate(
            { newPassword: data.password, uuid },
            {
                onSuccess: () => {
                    setSnackbarMessage(getLabel('passwordResetSuccess'))
                    setSnackbarOpen(true)
                    setTimeout(() => {
                        navigate('/login')
                    }, 10000)
                },
                onError: () => {
                    setSnackbarMessage(getLabel('passwordResetFailed'))
                    setSnackbarOpen(true)
                }
            }
        )
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false)
    }

    const { watch } = methods
    const passwordValue = watch('password')
    const confirmPasswordValue = watch('confirmPassword')

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 5,
                paddingBottom: 20
            }}>
            <Box
                sx={{
                    padding: 4,
                    maxWidth: 400,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '8px',
                    backgroundColor: euContext?.htmlColors?.login.backgroundColor,
                    borderColor: euContext?.htmlColors?.login.borderColor
                }}>
                <Typography
                    component='h1'
                    variant='h5'
                    sx={{ mb: 2, color: euContext?.htmlColors?.login.textColor }}>
                    {getLabel('resetPassword')}
                </Typography>
                {errorMessage && (
                    <Typography
                        color='error'
                        sx={{ mb: 2 }}>
                        {errorMessage}
                    </Typography>
                )}
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onButtonClicked)}>
                        <FieldSigninText
                            name='password'
                            label={getLabel('newPassword')}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <IconButton
                                    aria-label={getLabel('ariaTextPassword')}
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                            sx={{ width: '100%', mb: 2 }}
                        />
                        <FieldSigninText
                            name='confirmPassword'
                            label={getLabel('confirmPassword')}
                            type={showConfirmPassword ? 'text' : 'password'}
                            endAdornment={
                                <IconButton
                                    aria-label={getLabel('ariaTextPassword')}
                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}>
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                            sx={{ width: '100%', mb: 2 }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                            <Button
                                variant='contained'
                                disabled={isPending || passwordValue.length === 0 || confirmPasswordValue.length === 0}
                                type='submit'
                                sx={{
                                    width: '50%',
                                    borderRadius: '20px',
                                    marginTop: 1,
                                    backgroundColor: euContext?.htmlColors?.login.buttonBackgroundColor,
                                    color: euContext?.htmlColors?.login.buttonTextColor,
                                    marginBottom: '10px',
                                    '&:hover': {
                                        backgroundColor: euContext?.htmlColors?.login.hoverBackgroundColor,
                                        color: euContext?.htmlColors?.login.hoverTextColor
                                    }
                                }}>
                                {isPending ? getLabel('resetPasswordBtnLoading') : getLabel('resetPasswordBtn')}
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </Box>
    )
}

export default ResetPasswordPage
