import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getView } from 'views/viewUtils'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { ResumeStylesType } from '../utils/Constants'
import { getDashBoardDefaultValues } from './forms/defaultValues/FormDefaultValues'
import { DashboardSchema } from './forms/schemas/FormSchemas'

export const DashboardPage = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const styles: ResumeStylesType = euContext.resume.style
    const style: IStyles | undefined = styles ? styles[euContext.resume.currentStyle || 'Minimal'] : undefined
    const [viewResume, setViewResume] = useState<ReactNode>(getView(euContext.resume))

    const formContext = useForm({
        resolver: yupResolver(DashboardSchema),
        defaultValues: getDashBoardDefaultValues(euContext.resume),
        mode: 'onSubmit',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    useEffect(() => {
        formContext.reset(euContext.resume)
        setViewResume(getView(euContext.resume))
    }, [euContext.resume])

    return (
        <FormProvider {...formContext}>
            <form>
                <Stack
                    sx={{
                        paddingTop: '100px',
                        paddingBottom: '100px',
                        background: 'white'
                    }}
                    width='816px'>
                    <Box
                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                        alignSelf={style?.BodyAlign ?? 'end'}
                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                        fontFamily={style ? style[style?.BodyFontName] : 'cursive'}
                        width='816px'>
                        {viewResume}
                    </Box>
                </Stack>
            </form>
        </FormProvider>
    )
}
