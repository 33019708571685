import { Alignments, DateDelims, DateFormats, FontCases, FontNames, FontStyles, FontWeights, IStyles, ResumeAlignments } from '../models/Styles'
import { validResumeStyles } from '../utils/Constants'

const Savannah: IStyles = {
    StyleName: 'Savannah' as validResumeStyles,
    PrimaryFontName: FontNames.IBMPlexSansCondensed,
    SecondaryFontName: FontNames.IBMPlexSansCondensed,
    AlternateFontName: FontNames.Barlow,
    NameFontName: 'PrimaryFontName',
    NameFontSize: 32,
    NameFontWeight: FontWeights.Bold,
    NameFontStyle: FontStyles.Initial,
    NameAlign: Alignments.Start,
    NameFontCase: FontCases.Capitalize,
    NameFontColor: '#6a1b90',
    NameFontLetterSpacing: 1,
    TitleFontName: 'PrimaryFontName',
    TitleFontSize: 16,
    TitleFontWeight: FontWeights.Initial,
    TitleFontStyle: FontStyles.Initial,
    TitleAlign: Alignments.Start,
    TitleFontCase: FontCases.Capitalize,
    TitleFontColor: '#000000',
    TitleFontLetterSpacing: 1,
    AddressFontName: 'PrimaryFontName',
    AddressFontSize: 12,
    AddressFontWeight: FontWeights.Initial,
    AddressFontStyle: FontStyles.Initial,
    AddressAlign: Alignments.Start,
    AddressFontCase: FontCases.None,
    AddressFontColor: '#000000',
    AddressFontLetterSpacing: 1,
    SectionFontName: 'PrimaryFontName',
    SectionFontSize: 16,
    SectionFontWeight: FontWeights.Bold,
    SectionFontStyle: FontStyles.Initial,
    SectionAlign: Alignments.Start,
    SectionFontCase: FontCases.Uppercase,
    SectionFontColor: '#6a1b90',
    SectionFontLetterSpacing: 1,
    SectionHRLine: true,
    SectionHRColor: '#e0e0e0',
    DateFontName: 'PrimaryFontName',
    DateFontSize: 12,
    DateFontWeight: FontWeights.Initial,
    DateFontStyle: FontStyles.Initial,
    DateAlign: Alignments.Start,
    DateFontCase: FontCases.None,
    DateFormat: DateFormats.MMyyyy,
    DateDelim: DateDelims.Dash,
    DateFontColor: '#e0e0e0',
    DateFontLetterSpacing: 1,
    CompanyFontName: 'PrimaryFontName',
    CompanyFontSize: 12,
    CompanyFontWeight: FontWeights.Initial,
    CompanyFontStyle: FontStyles.Initial,
    CompanyAlign: Alignments.Start,
    CompanyFontCase: FontCases.None,
    CompanyFontColor: '#000000',
    CompanyFontLetterSpacing: 1,
    JobTitleFontName: 'PrimaryFontName',
    JobTitleFontSize: 12,
    JobTitleFontWeight: FontWeights.Bold,
    JobTitleFontStyle: FontStyles.Initial,
    JobTitleAlign: Alignments.Start,
    JobTitleFontCase: FontCases.None,
    JobTitleFontColor: '#000000',
    JobTitleFontLetterSpacing: 1,
    BodyFontName: 'PrimaryFontName',
    BodyFontSize: 12,
    BodyFontWeight: FontWeights.Initial,
    BodyFontStyle: FontStyles.Initial,
    BodyAlign: Alignments.Start,
    BodyFontCase: FontCases.None,
    BodyFontColor: '#000000',
    BodyFontLetterSpacing: 1,
    Special: '',
    Alignment: ResumeAlignments.Left,
    SpaceHeader: 4,
    SpaceSectionTitle: 12,
    SpaceCard: 10,
    SpaceSection: 2,
    SpaceBorder: 1,
    BorderLocation: '',
    BorderStyle: '',
    seqNum: 1,
    ShowGraphic: false
}

export default Savannah
