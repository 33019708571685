import React, { useEffect } from 'react'
import { DashboardPage } from '../pages/DashboardPage'

const PrintResumeView = () => {
    useEffect(() => {
        window.print()
    }, [])

    return (
        <div style={{ padding: '20px', pointerEvents: 'none' }}>
            <DashboardPage />
        </div>
    )
}

export default PrintResumeView
