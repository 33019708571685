import { FormControl, InputAdornment, TextField, type InputProps } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type FormInputProps = {
    name: string
    label: string
    endAdornment?: React.ReactNode
    errorMessage?: string
} & InputProps

const FieldSigninText = ({ name, label, endAdornment, errorMessage }: FormInputProps): JSX.Element => {
    const { control } = useFormContext()

    useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
                box-shadow: 0 0 0 30px white inset !important;
                -webkit-text-fill-color: #000 !important;
                caret-color: auto !important;
            }
        `
        document.head.appendChild(style)
        return () => {
            document.head.removeChild(style)
        }
    }, [])

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl
                    error={!!error}
                    fullWidth
                    margin='normal'>
                    <TextField
                        variant='standard'
                        label={label}
                        value={value ?? ''}
                        error={errorMessage ? true : false}
                        helperText={errorMessage || ''}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : null
                        }}
                        fullWidth
                    />
                </FormControl>
            )}
        />
    )
}

export default FieldSigninText
