import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { forgotPasswordLink, loginApiLink, loginGoogleLink, loginLinkedInLink, logoutLink, registerLink, registerLinkedInLink, resetPasswordLink } from '../utils/Constants'

interface LoginResponse {
    accessToken: string
    user: IUser
    resume: IResume
}

const linkedInLogin = async (code: string, state: string) => {
    const response = await axios.post(loginLinkedInLink, { code, state })
    return response.data
}

const linkedInRegister = async (code: string, state: string) => {
    const response = await axios.post(registerLinkedInLink, { code, state })
    return response.data
}

const login = async ({ email, password, subdomain }: { email: string; password: string; subdomain: string }): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(loginApiLink, { email, password, subdomain })
    return response.data
}

const logout = async ({ email, subdomain }: { email: string; subdomain: string }): Promise<{ message: string }> => {
    const response = await axios.post(logoutLink, { email, subdomain })
    return response.data
}

const register = async ({ email, password, firstName, lastName, subdomain }: { email: string; password: string; firstName: string; lastName: string; subdomain: string }): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(registerLink, { email, password, firstName, lastName, subdomain })
    return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const googleLogin = async (googleUser: any): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(loginGoogleLink, { googleUser })
    return response.data
}

const forgotPassword = async ({ email }: { email: string }) => {
    const response = await axios.post(forgotPasswordLink, { email })
    return response.data
}

const resetPassword = async ({ newPassword, uuid }: { newPassword: string; uuid: string }): Promise<{ message: string }> => {
    const response = await axios.post(resetPasswordLink, { newPassword, resetPasswordUuid: uuid })
    return response.data
}

export const useLinkedInLogin = (code: string, state: string) => {
    return useQuery({
        queryKey: ['linkedInLogin', code, state],
        queryFn: () => linkedInLogin(code, state),
        enabled: !!code // Ensure the query runs only when the code is available
    })
}

export const useLinkedInRegister = (code: string, state: string) => {
    return useQuery({
        queryKey: ['linkedInRegister', code, state],
        queryFn: () => linkedInRegister(code, state),
        enabled: !!code // Ensure the query runs only when the code is available
    })
}

export const useLogin = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: login,
        onError: (error) => {
            console.error('Login error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

export const useLogout = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: logout,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

export const useRegister = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: register,
        onError: (error) => {
            console.error('Register error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
            queryClient.invalidateQueries({
                queryKey: ['users']
            })
        }
    })
}

export const useGoogleLogin = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: googleLogin,
        onError: (error) => {
            console.error('Google login error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

export const useResetPassword = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: resetPassword,
        onError: (error) => {
            console.error(error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

export const useForgotPassword = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: forgotPassword,
        onError: (error) => {
            console.error(error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}
