import { Box, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import CircleNameComponent from '../components/resume/name/CircleNameComponent'
import NameComponent from '../components/resume/name/NameComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import { IResume } from '../models/Resume'

const Orchid = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Orchid.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='column'
            width='100%'
            rowGap={0}>
            <Box
                mb={style.SpaceHeader}
                width='100%'>
                <Stack direction='row'>
                    <Stack
                        direction='column'
                        width='100%'
                        rowGap={0}>
                        <NameComponent mt={0} />
                        <Box
                            border={1}
                            width='max-content'
                            alignSelf={style.TitleAlign}
                            paddingLeft={1}
                            paddingRight={1}
                            paddingTop={0.5}>
                            <TitleComponent sx={{ width: '100%', lineHeight: 1, verticalAlign: 'middle' }} />
                        </Box>
                    </Stack>
                    <CircleNameComponent />
                </Stack>
            </Box>
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={3}
                zIndex={5}>
                <Stack
                    direction='column'
                    width='540px'
                    paddingLeft={0}
                    paddingRight={3}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return <value.cardComponent key={value.cardType} />
                    })}
                </Stack>
                <Stack
                    direction='column'
                    width='275px'
                    paddingLeft={3}
                    paddingRight={3}
                    rowGap={3}>
                    <Box
                        border={1}
                        paddingLeft={2}
                        paddingBottom={2}>
                        <ContactComponent
                            includeLabel={true}
                            includeAddress={true}
                            hideState={false}
                            hideCity={false}
                            hideStreet={true}
                            hideZip={true}
                            hideEmail={false}
                            hideLinkedIn={false}
                            hidePhone={false}
                            includeIcons={true}
                        />
                    </Box>
                    <Box
                        border={1}
                        paddingLeft={2}
                        paddingBottom={2}
                        paddingTop={2}>
                        <TrainingListComponent />
                    </Box>
                    <Box
                        border={1}
                        paddingLeft={2}
                        paddingBottom={2}>
                        <SkillsBulletListComponent />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Orchid
