import { Box, Button, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useForgotPassword } from '../hooks/useAuth'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const ForgotPasswordPage = (): JSX.Element => {
    const [emailValue, setEmailValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const navigate = useNavigate()
    const { mutate: forgotPasswordMutate, isPending } = useForgotPassword()

    const onButtonClicked = (): void => {
        forgotPasswordMutate(
            { email: emailValue },
            {
                onSuccess: () => {
                    setSnackbarOpen(true)
                    setSnackbarMessage(getLabel('passwordResetEmail'))
                    setTimeout(() => {
                        navigate('/login')
                    }, 10000)
                },
                onError: () => {
                    setSnackbarMessage(getLabel('passwordResetEmailFailed'))
                    setSnackbarOpen(true)
                }
            }
        )
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false)
    }

    return (
        <Box
            sx={{
                marginTop: '150px',
                backgroundColor: euContext?.htmlColors?.login.backgroundColor,
                marginBottom: '100px'
            }}>
            <Box
                sx={{
                    padding: 4,
                    width: '470px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                <Typography
                    component='h1'
                    variant='h5'
                    sx={{ mb: 1 }}>
                    {getLabel('forgotPassword')}
                </Typography>

                <Typography
                    component='p'
                    variant='body2'
                    sx={{ mb: 1, color: euContext?.htmlColors?.login.buttonTextColor }}>
                    {getLabel('enterEmail')}
                </Typography>
                {errorMessage && (
                    <Typography
                        color='error'
                        sx={{ mb: 2 }}>
                        {errorMessage}
                    </Typography>
                )}

                <TextField
                    variant='standard'
                    label={getLabel('email')}
                    value={emailValue}
                    onChange={(e) => {
                        setEmailValue(e.target.value)
                        setErrorMessage('')
                    }}
                    placeholder={getLabel('placeholderEmail')}
                    fullWidth
                    autoFocus
                    error={errorMessage.length > 0}
                    sx={{
                        width: '85%',
                        marginBottom: '20px'
                    }}
                />

                <Button
                    variant='contained'
                    color='primary'
                    disabled={isPending || emailValue.length === 0}
                    onClick={onButtonClicked}
                    sx={{
                        width: '50%',
                        borderRadius: '20px',
                        fontSize: '.7rem',
                        backgroundColor: euContext?.htmlColors?.login.buttonBackgroundColor,
                        color: euContext?.htmlColors?.login.buttonTextColor,
                        marginBottom: '10px',
                        '&:hover': {
                            backgroundColor: euContext?.htmlColors?.login.hoverBackgroundColor,
                            color: euContext?.htmlColors?.login.hoverTextColor
                        }
                    }}>
                    {isPending ? getLabel('sendingLink') : getLabel('sendBtn')}
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </Box>
    )
}

export default ForgotPasswordPage
