import { Box, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationCondensedListComponent from '../components/resume/educations/EducationCondensedListComponent'
import { HorizontalLine } from '../components/resume/misc/MiscComponents'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import CapabilitiesBulletListComponent from '../components/resume/simpleLists/CapabilitiesBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import { IResume } from '../models/Resume'

const Harbor = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Harbor.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='column'
            border={1}
            borderColor='lightgray'
            padding={3}
            rowGap={0}>
            <Stack
                direction='row'
                justifyContent='space-between'
                width='100%'>
                <Box
                    mb={style.SpaceHeader}
                    width='50%'>
                    <NameVerticleComponent />
                    <TitleComponent />
                </Box>
                <Box
                    mb={style.SpaceHeader}
                    width='50%'>
                    <ContactComponent
                        includeLabel={false}
                        includeAddress={true}
                        hideState={false}
                        hideCity={false}
                        hideStreet={true}
                        hideZip={true}
                        hideEmail={false}
                        hideLinkedIn={false}
                        hidePhone={false}
                        includeIcons={true}
                        iconOnRight={true}
                    />
                </Box>
            </Stack>
            <HorizontalLine
                mt={6}
                mb={6}
            />
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={2}>
                <Stack
                    direction='column'
                    width='275px'
                    paddingRight={2}
                    rowGap={style.SpaceSection}>
                    <SummaryComponent />
                    <HorizontalLine
                        mt={3}
                        mb={-10}
                    />
                    <EducationCondensedListComponent />
                    <HorizontalLine
                        mt={3}
                        mb={-10}
                    />
                    <CapabilitiesBulletListComponent />
                    <HorizontalLine
                        mt={3}
                        mb={-10}
                    />
                    <TrainingListComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='474px'
                    paddingLeft={2}
                    borderLeft={1}
                    borderColor='lightgray'>
                    {orderUsedCards.map((value: defaultCard, index: number) => {
                        return (
                            <>
                                <value.cardComponent key={value.cardType} /> {index < orderUsedCards.length - 1 && <HorizontalLine />}
                            </>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Harbor
