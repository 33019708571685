import { Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import InitialCircleComponent from '../components/resume/name/InitialCircleComponent'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import SkillsBlockListComponent from '../components/resume/simpleLists/SkillsBlockListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'

const Horizon = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Horizon.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack direction='column'>
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={0}>
                <Stack
                    direction='column'
                    width='250px'
                    paddingLeft={3}
                    paddingRight={3}
                    sx={{ backgroundColor: 'lightgrey' }}
                    rowGap={`${style.SpaceSection}px`}>
                    <InitialCircleComponent
                        picHeight={150}
                        picWidth={150}
                        picMT={4}
                        picBT={3}
                    />
                    <ContactComponent
                        includeAddress={true}
                        hideState={false}
                        hideZip={false}
                        hideCity={true}
                        hideStreet={true}
                        includeIcons={true}
                        sectionBgColor='white'
                    />
                    <EducationListComponent sectionBgColor='white' />
                    <SkillsBlockListComponent
                        sectionBgColor='white'
                        itemBgColor='gainsboro'
                    />
                </Stack>
                <Stack
                    direction='column'
                    width='565px'
                    paddingLeft={3}
                    paddingRight={3}>
                    <Stack
                        direction='column'
                        height='218px'
                        width='100%'
                        sx={{ verticalAlign: 'middle' }}>
                        <NameVerticleComponent
                            sx={{ letterSpacing: '6px' }}
                            mt={3}
                        />
                        <hr style={{ width: '10%', borderWidth: '2px', borderStyle: 'solid', borderColor: 'black' }} />
                        <TitleComponent />
                    </Stack>
                    {orderUsedCards.map((value: defaultCard) => {
                        return (
                            <value.cardComponent
                                key={value.cardType}
                                sectionBgColor='lightgrey'
                            />
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Horizon
