import { Logout } from '@mui/icons-material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { purple } from '@mui/material/colors'
import { isAxiosError } from 'axios'
import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useLogout } from '../hooks/useAuth'
import Logo from '../images/logos/logo_pro.png'
import { getLabel } from '../labels/labelUtils'
import { IUser } from '../models/User'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const Header = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { mutate: logoutMutate } = useLogout()
    const isLoginPage = location.pathname === '/login'
    const isLoggedIn = Boolean(euContext.user._id)

    const logoutUser = () => {
        logoutMutate(
            { email: euContext.user.email, subdomain: euContext.domain },
            {
                onSuccess: () => {
                    euContext.setUser({} as IUser)
                },
                onError: (error) => {
                    if (isAxiosError(error) && error.response) {
                        console.error(error.response.data.message)
                    } else {
                        console.error('An unknown error occurred')
                    }
                }
            }
        )
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('expireDate')

        navigate('/login')
    }

    return (
        <AppBar
            sx={{
                backgroundColor: euContext.htmlColors?.header.backgroundColor,
                borderTop: `10px solid ${purple[500]}`
            }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }} />
                <img
                    src={Logo}
                    style={{ height: '40px' }}
                />

                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    {!isLoggedIn && isLoginPage && (
                        <Button
                            color='inherit'
                            startIcon={<AccountCircle />}
                            onClick={() => navigate(isLoginPage ? '/register' : '/login')}
                            sx={{
                                color: euContext.htmlColors?.header.textColor,
                                '&:hover': {
                                    color: euContext.htmlColors?.header.hoverTextColor
                                }
                            }}>
                            {isLoginPage ? getLabel('register') : getLabel('login')}
                        </Button>
                    )}
                    {isLoggedIn && !isLoginPage && (
                        <Button
                            color='inherit'
                            startIcon={<Logout />}
                            onClick={logoutUser}
                            sx={{
                                color: euContext.htmlColors?.header.textColor,
                                '&:hover': {
                                    color: euContext.htmlColors?.header.hoverTextColor
                                }
                            }}>
                            {getLabel('logout')}
                        </Button>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    )
}
