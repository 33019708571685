import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { FormProvider, useForm } from 'react-hook-form'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { AllDefaultCards, ButtonTypes, defaultCard } from 'utils/Util'
import ResumeNameInputText from '../../components/forms/ResumeNameInputText'
import { OrderResumeComponent } from '../../components/OrderResumeComponent'
import { useUpdateResumeField } from '../../hooks/useResume'
import { getLabel } from '../../labels/labelUtils'
import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'

export interface OrderResumeManagerProps {
    open: boolean
    onClose: () => void
}

const OrderResumeManager = ({ open, onClose }: OrderResumeManagerProps) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const formMethods = useForm({
        defaultValues: { resumeName: euContext.resume.resumeName || '' }
    })

    const [orderUsedCards, setOrderUsedCards] = useState<defaultCard[]>([])
    const [orderFreeCards, setOrderFreeCards] = useState<defaultCard[]>([])

    useEffect(() => {
        if (euContext.resume.currentStyle) {
            const usedCards =
                AllDefaultCards[euContext.resume.currentStyle]
                    ?.filter((value) => {
                        return (euContext.resume.cardOrder[euContext.resume.currentStyle]?.[value.cardType] ?? -1) >= 0
                    })
                    .sort((a, b) => {
                        return (euContext.resume.cardOrder[euContext.resume.currentStyle]?.[a.cardType] ?? -1) - (euContext.resume.cardOrder[euContext.resume.currentStyle]?.[b.cardType] ?? -1)
                    }) || []

            const freeCards =
                AllDefaultCards[euContext.resume.currentStyle]
                    ?.filter((value) => {
                        return (euContext.resume.cardOrder[euContext.resume.currentStyle]?.[value.cardType] ?? -1) === -1
                    })
                    .sort((a, b) => a.title.localeCompare(b.title)) || []

            setOrderUsedCards(usedCards)
            setOrderFreeCards(freeCards)
        }
    }, [euContext.resume])

    const handleDrag = (index: number, deltaY: number) => {
        const newIndex = index + Math.round(deltaY / 60)
        if (newIndex >= 0 && newIndex < orderUsedCards.length && newIndex !== index) {
            const updatedCards = [...orderUsedCards]
            const [movedCard] = updatedCards.splice(index, 1)
            updatedCards.splice(newIndex, 0, movedCard)
            setOrderUsedCards(updatedCards)

            const updatedOrder = updatedCards.reduce(
                (acc, card, idx) => {
                    acc[card.cardType] = idx
                    return acc
                },
                {} as Record<string, number>
            )

            const copyResume = { ...euContext.resume }
            copyResume.cardOrder[euContext.resume.currentStyle] = updatedOrder

            updateResumeField(
                { id: copyResume._id, fieldName: `cardOrder.${euContext.resume.currentStyle}`, fieldValue: updatedOrder },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )
        }
    }

    const addCard = (card: defaultCard) => {
        const copyResume = { ...euContext.resume }
        const style = copyResume.currentStyle

        if (!copyResume.cardOrder[style]) {
            copyResume.cardOrder = { ...copyResume.cardOrder, [style]: {} }
        }
        copyResume.cardOrder[style][card.cardType] = orderUsedCards.length

        updateResumeField(
            { id: copyResume._id, fieldName: `cardOrder.${style}.${card.cardType}`, fieldValue: orderUsedCards.length },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const removeCard = (card: defaultCard) => {
        const copyResume = { ...euContext.resume }
        const style = copyResume.currentStyle

        const seqNum = copyResume.cardOrder[style]?.[card.cardType] ?? -1
        orderUsedCards.forEach((value) => {
            if (value.cardType === card.cardType) copyResume.cardOrder[style][value.cardType] = -1
            else if ((copyResume.cardOrder[style][value.cardType] ?? -1) > seqNum) {
                copyResume.cardOrder[style][value.cardType] = (copyResume.cardOrder[style][value.cardType] ?? 0) - 1
            }
        })

        updateResumeField(
            { id: copyResume._id, fieldName: `cardOrder.${style}`, fieldValue: copyResume.cardOrder[style] },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    if (isPending) {
        return <LoadingState message={getLabel('updating')} />
    }

    if (isError) {
        return <ErrorState message={getLabel('errorUpdatingResumeField')} />
    }

    return (
        <FormProvider {...formMethods}>
            <Dialog
                onClose={onClose}
                open={open}
                maxWidth='lg'
                fullWidth
                sx={{ zIndex: 2300 }}>
                <DialogTitle>
                    {getLabel('orderResumeSections')}
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <ResumeNameInputText
                        name='resumeName'
                        label={getLabel('resumeName')}
                        fontFamily='Roboto'
                        variant='outlined'
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <List sx={{ mt: 4 }}>
                        {orderUsedCards.map((value, index) => (
                            <Draggable
                                key={index}
                                axis='y'
                                bounds='parent'
                                onStop={(e, data) => handleDrag(index, data.y)}>
                                <ListItem sx={{ mb: 2, cursor: 'grab' }}>
                                    <OrderResumeComponent
                                        card={value}
                                        type={ButtonTypes.Remove}
                                        buttonClicked={() => removeCard(value)}
                                    />
                                </ListItem>
                            </Draggable>
                        ))}
                    </List>
                    {orderFreeCards.map((value, index) => (
                        <OrderResumeComponent
                            card={value}
                            type={ButtonTypes.Add}
                            buttonClicked={() => addCard(value)}
                            key={100 + index}
                        />
                    ))}
                </DialogContent>
            </Dialog>
        </FormProvider>
    )
}

export default OrderResumeManager
