import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const Footer = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    return (
        <Box
            sx={{
                backgroundColor: euContext?.htmlColors?.footer.backgroundColor,
                padding: '20px',
                textAlign: 'center',
                border: '1px solid',
                borderColor: euContext?.htmlColors?.footer.borderColor,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 2000
            }}>
            <Grid
                container
                spacing={2}
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Grid
                    item
                    xs={12}
                    sm='auto'>
                    <Typography variant='body2'>{getLabel('companyAddress')}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm='auto'>
                    <Typography variant='body2'>{getLabel('companyPhone')}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm='auto'>
                    <Typography variant='body2'>&copy; {getLabel('companyCopyright')}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Footer
