import Stack from '@mui/material/Stack'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import NameComponent from '../components/resume/name/NameComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'

const Vivid = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Vivid.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack
            direction='row'
            fontSize={resume.style[resume.currentStyle].BodyFontSize}
            fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
            fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
            fontFamily={resume.style[resume.currentStyle].BodyFontName}
            marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
            columnGap={6}>
            <Stack
                direction='column'
                width='200px'
                borderRight='1px solid lightgray'
                rowGap={resume.style[resume.currentStyle].SpaceSection}>
                <PictureComponent
                    picWidth={150}
                    picHeight={150}
                />
                <ContactComponent
                    includeAddress={true}
                    hideStreet={true}
                    hideZip={true}
                    hideState={false}
                    hideCity={false}
                />
                <hr style={{ backgroundColor: 'lightgray', height: '2px', width: '80%', alignSelf: 'start', margin: 0, border: 1 }} />
                <EducationListComponent />
                <hr style={{ backgroundColor: 'lightgray', height: '2px', width: '80%', alignSelf: 'start', margin: 0, border: 1 }} />
                <SkillsBulletListComponent />
            </Stack>
            <Stack
                direction='column'
                width='600px'>
                <NameComponent />
                <TitleComponent />
                <hr style={{ backgroundColor: 'lightgray', height: '2px', width: '80%', alignSelf: 'start', margin: 0, marginTop: 18, marginBottom: 48 }} />
                {orderUsedCards.map((value: defaultCard, index: number) => {
                    return (
                        <>
                            <value.cardComponent key={value.cardType} />
                            {index + 1 < orderUsedCards.length && <hr style={{ backgroundColor: 'lightgray', height: '2px', width: '80%', alignSelf: 'start', margin: 0, marginBottom: 24 }} />}
                        </>
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default Vivid
